import memoizeOne from 'memoize-one'
import {
  filter,
  partition,
  pick,
  pipe,
  uniqBy,
} from 'ramda'

import { getUniqueRanges, haveAlerts, haveTargets } from '~/src/DataType/TargetRange/utils'
import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  memoize,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'
import { path as lightsOnPath } from '~/src/UI/Icons/LightsOn'

import { DATA_GROUPING, ROOM_GRAPH_SINGLE } from './constants'

const logger = createLogger('Chart/Utils#Guides')

const DAY_RANGE_KEYS = [
  'alertMax',
  'alertMin',
  'targetMax',
  'targetMin',
]
const NIGHT_RANGE_KEYS = [
  'nightAlertMax',
  'nightAlertMin',
  'nightTargetMax',
  'nightTargetMin',
]

const getTopLevelGuides = memoizeOne(({ chartData, theme }) => {
  let guides = chartData.guides ?? EMPTY_ARRAY
  if (guides.some(guide => guide.type === 'lights_on')) {
    /* const lightsOn = createElement(LightsOn) */
    guides = guides.map(guide => (guide.type === 'lights_on'
      ? { ...guide, icon: lightsOnPath, iconFillColor: theme.palette.gray }
      : guide))
  }
  return guides
})

export const getActiveTargets = shallowEqualsMemoizer(({
  chartData,
  activeTargetType,
  growlog,
  phases,
  room: selectedRoom,
  selectedHarvest,
  selectedZones,
  targetRanges,
}) => {
  const { range } = chartData
  if (!activeTargetType || !range) {
    logger.debug('[getActiveTargets] Either no activeTargetType or no range provided.', { activeTargetType, range })
    return null
  }
  const rangeInterval = getDateTime(range.start).until(getDateTime(range.end))
  const selectedZonesSet = new Set(selectedZones)
  const activeTargets = (activeTargetType && range) ? pipe(
    Object.values,
    filter(({ dataTypeKey, phaseId, room, startDate, endDate }) => {
      const notActiveType = dataTypeKey !== activeTargetType.key
      if (notActiveType) {
        logger.debug(`[getActiveTargets] Skipping target (${dataTypeKey}) because not active type:`, activeTargetType.key)
        return false
      }
      let inRange = false
      if (!startDate) return inRange
      if (endDate) {
        const targetInterval = getDateTime(startDate).until(getDateTime(endDate))
        inRange = rangeInterval.overlaps(targetInterval)
      } else {
        inRange = getDateTime(startDate) <= rangeInterval.end
      }

      if (!inRange) {
        logger.debug('[getActiveTargets] Skipping target because not in time range:')
        return false
      }

      if (!growlog) {
        if (Array.isArray(selectedRoom?.currentHarvests) && phases?.[phaseId]) {
          const { currentHarvests: harvests } = selectedRoom
          const { [phaseId]: phase = EMPTY_OBJECT } = phases
          const { harvestId, phaseType } = phase
          const harvest = harvestId ? harvests.find(({ id }) => harvestId == id) : null
          if (!harvestId || !harvest) return false

          // This is a HG phase target range; filter by selected zones
          const zonesKey = `${phaseType.toLowerCase()}Zones`
          const cultivars = harvest?.cultivars ?? []

          return cultivars.some(c => c[zonesKey]?.some(zone => selectedZonesSet.has(zone)))
        }
        return room === selectedRoom?.id
      }
      if (selectedHarvest?.phases?.length) {
        return selectedHarvest.phases.some(({ id }) => phaseId === id)
      }
      return false
    }),
    getUniqueRanges
  )(targetRanges) : null

  return activeTargets
}, { depth: 2 })

const hasSameNightDay = targetRange => {
  const {
    alertMin,
    alertMax,
    nightAlertMin,
    nightAlertMax,
    targetMin,
    targetMax,
    nightTargetMin,
    nightTargetMax,
  } = targetRange

  return alertMin === nightAlertMin
    && alertMax === nightAlertMax
    && targetMin === nightTargetMin
    && targetMax === nightTargetMax
}

const getTargetGuides = ({
  chartData,
  theme,
  activeTargetType,
  growlog,
  room: selectedRoom,
  selectedHarvest,
  selectedZones,
  targetRanges,
  viewMode,
  dataTypes,
  graphDataTypes,
  groups,
  phases,
}) => {
  const {
    range = EMPTY_OBJECT,
    guides = EMPTY_ARRAY
  } = chartData
  const activeTargets = getActiveTargets({
    chartData,
    activeTargetType,
    growlog,
    phases,
    room: selectedRoom,
    selectedHarvest,
    selectedZones,
    targetRanges,
  })
  // If no targets or no chart data range, we can't plot guides
  if (!activeTargets?.length || !range || (!range.start && !range.end)) {
    return groups
  }
  const chartStart = getDateTime(range.start)
  const chartEnd = getDateTime(range.end)

  const [nightDayTargets, allTimeTargets] = partition(tr => tr.useNightDay && !hasSameNightDay(tr), activeTargets)
  const effectiveTarget = memoize(tr => {
    const { startDate, endDate: originalEndDate } = tr
    const startDT = getDateTime(startDate)
    let endDate = originalEndDate
    if (tr.phaseId) {
      endDate = getDateTime(endDate).minus({ milliseconds: 1 })
    }
    const endDT = endDate ? getDateTime(endDate) : chartEnd
    return pick([
      ...DAY_RANGE_KEYS,
      ...NIGHT_RANGE_KEYS,
      'startDate',
      'endDate',
      'dataTypeKey',
    ], {
      ...tr,
      startDate: chartStart > startDT ? range.start : startDT.toUTC().toISO(),
      endDate: chartEnd > endDT ? endDT.toUTC().toISO() : range.end
    })
  })

  const targetGuides = []
  if (nightDayTargets.length) {
    targetGuides.push(...uniqBy(effectiveTarget, nightDayTargets).flatMap(targetRange => {
      const result = []
      const {
        alertMax,
        alertMin,
        nightAlertMax,
        nightAlertMin,
        dataTypeKey,
        startDate,
        endDate,
        targetMax,
        targetMin,
        nightTargetMax,
        nightTargetMin,
      } = effectiveTarget(targetRange)
      const { [dataTypeKey]: dataType } = dataTypes
      const targetStart = getDateTime(startDate)
      const targetEnd = getDateTime(endDate).endOf('day')
      const targetInterval = targetStart.until(targetEnd)
      const lastGuideIndex = guides.length - 1

      guides.forEach((guide, index) => {
        const { date, toDate } = guide
        const guideStart = getDateTime(date)
        const guideEnd = getDateTime(toDate)
        const guideInterval = guideStart.until(guideEnd)

        if (!targetInterval.overlaps(guideInterval)) return

        const { [index + 1]: nextGuide = EMPTY_OBJECT } = guides
        if (alertMax != null && alertMin != null) {
          result.push({
            get type() { return `DayAlert_${this.date}_${this.toDate}` },
            toY: alertMin,
            y: alertMax,
            date,
            toDate,
            fillAlpha: theme.palette.states.active,
            fillColor: dataType.color,
            dataType: dataTypeKey,
          })
        }
        if (nightAlertMax != null && nightAlertMin != null) {
          // Add night alerts prior to first lights on period
          if (index == 0 && targetStart < guideStart) {
            result.push({
              get type() { return `NightAlert_${this.date}_${this.toDate}` },
              toY: nightAlertMin,
              y: nightAlertMax,
              date: targetStart.toISO(),
              toDate: guideStart.toISO(),
              fillAlpha: theme.palette.states.active,
              fillColor: dataType.color,
              dataType: dataTypeKey,
            })
          }
          if (index == lastGuideIndex && targetEnd > guideEnd) {
            // Add night alerts after last lights-on period
            result.push({
              get type() { return `NightAlert_${this.date}_${this.toDate}` },
              toY: nightAlertMin,
              y: nightAlertMax,
              date: guideEnd.toISO(),
              toDate: targetEnd.toISO(),
              fillAlpha: theme.palette.states.active,
              fillColor: dataType.color,
              dataType: dataTypeKey,
            })
            return
          }
          result.push({
            get type() { return `NightAlert_${this.date}_${this.toDate}` },
            toY: nightAlertMin,
            y: nightAlertMax,
            date: toDate,
            toDate: nextGuide.date,
            fillAlpha: theme.palette.states.active,
            fillColor: dataType.color,
            dataType: dataTypeKey,
          })
        }
        if (targetMax != null && targetMin != null) {
          result.push({
            type: `TargetRange_${dataType.key}`,
            toY: targetMin,
            y: targetMax,
            date,
            toDate,
            fillAlpha: theme.palette.states.active,
            fillColor: dataType.color,
            dataType: dataTypeKey,
          })
        }
        if (nightTargetMax != null && nightTargetMin != null) {
          if (index == 0 && targetStart < guideStart) {
            result.push({
              type: `NightTargetRange_${dataType.key}`,
              toY: nightTargetMin,
              y: nightTargetMax,
              date: targetStart.toISO(),
              toDate: guideStart.toISO(),
              fillAlpha: theme.palette.states.active,
              fillColor: dataType.color,
              dataType: dataTypeKey,
            })
          }
          if (index == lastGuideIndex && targetEnd > guideEnd) {
            result.push({
              type: `NightTargetRange_${dataType.key}`,
              toY: nightTargetMin,
              y: nightTargetMax,
              date: guideEnd.toISO(),
              toDate: targetEnd.toISO(),
              fillAlpha: theme.palette.states.active,
              fillColor: dataType.color,
              dataType: dataTypeKey,
            })
            return
          }
          result.push({
            type: `NightTargetRange_${dataType.key}`,
            toY: nightTargetMin,
            y: nightTargetMax,
            date: toDate,
            toDate: nextGuide.date,
            fillAlpha: theme.palette.states.active,
            fillColor: dataType.color,
            dataType: dataTypeKey,
          })
        }
      })
      return result
    }))
  }
  if (allTimeTargets.length) {
    targetGuides.push(...uniqBy(effectiveTarget, allTimeTargets).flatMap(targetRange => {
      const result = []
      const { dataTypeKey, startDate, endDate } = targetRange
      const { [dataTypeKey]: dataType } = dataTypes
      const date = startDate > range.start ? startDate : range.start
      const toDate = endDate < range.end ? endDate : range.end

      const fa = 0.16

      if (haveAlerts(targetRange)) {
        const { alertMax, alertMin } = targetRange
        if (alertMax && alertMin) {
          result.push({
            name: 'alertMax',
            y: alertMax,
            toY: alertMin,
            date,
            toDate,
            fillAlpha: fa,
            fillColor: dataType.color,
            dataType: dataTypeKey,
          })
        }
      }

      if (haveTargets(targetRange)) {
        const { targetMax, targetMin } = targetRange
        result.push({
          name: 'TargetRange',
          toY: targetMin,
          y: targetMax,
          date,
          toDate,
          fillAlpha: fa,
          fillColor: dataType.color,
          dataType: dataTypeKey,
        })
      }
      return result
    }))
  }

  const {
    [viewMode]: groupingHandler = DATA_GROUPING[ROOM_GRAPH_SINGLE],
  } = DATA_GROUPING

  const newGroups = groupingHandler({
    data: targetGuides,
    dataTypes,
    graphDataTypes,
    theme,
    type: 'guides',
    reducerInitial: groups,
  })

  const { 0: firstGuide } = targetGuides

  const [targetKey, targetGroup] = Object.entries(newGroups).find(([, group]) => (
    group.guides && group.guides.includes(firstGuide)
  )) ?? EMPTY_ARRAY

  if (targetGroup) {
    const graphs = targetGroup.graphs.map(graph => {
      const { dataTypeKey } = activeTargets[0]
      const { dataType } = graph
      if (dataType !== dataTypeKey) {
        return { ...graph, strokOpacity: 0.15 }
      }
      return graph
    })
    return {
      ...newGroups,
      [targetKey]: { ...targetGroup, graphs }
    }
  }

  return newGroups
}

export const getGuides = props => ({ guides: getTopLevelGuides(props), groups: getTargetGuides(props) })
