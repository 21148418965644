import { useMemo } from 'react'

import { QueryClient, useQuery } from '@tanstack/react-query'
import memoizeOne from 'memoize-one'
import { omit } from 'ramda'
import { useReduxBundlerStore } from 'redux-bundler-hook'

import { addBreadcrumb, captureEvent } from '@sentry/react'
import { minutes } from 'milliseconds'
import queryString from 'query-string'

import config from '~/src/App/config'
import { REQUEST_ABORTED } from '~/src/App/constants'
import { getTokens, refreshTokens } from '~/src/Lib/Auth'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { EMPTY_OBJECT, getClientId } from '~/src/Lib/Utils'

const localStorage = safeStorage('local')
const logger = createLogger('IO/API')
const global = getGlobal()
const debugMode = Boolean(localStorage.debug)
const notProd = config.ENVIRONMENT !== 'production'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: minutes(5),
    }
  }
})
if (debugMode && notProd) {
  global.queryClient = queryClient
}

const IOError = {
  isIOError: true,
  get message() {
    return this.error.message
  },
  get status() {
    return this.error.status
  },
  get response() {
    return this.error.response
  },
  toString() { return this.message }
}
function ioErrorFactory(message, status, response, meta) {
  return Object.assign(Object.create(IOError), {
    error: {
      message,
      status,
      response,
    },
    meta
  })
}

const getDefaultHeaders = (accessToken, facility) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${accessToken}`,
  'X-App': config.APP,
  'X-Facility': facility || '',
  'X-Client': getClientId(),
  'X-Client-Version': debugMode && localStorage.clientVersion
    ? localStorage.clientVersion
    : config.VERSION,
})

const methods = {
  fetch: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'GET',
  }),
  create: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'POST',
  }),
  update: (apiFetch, url, payload, opts) => apiFetch(url, payload, {
    ...opts,
    method: 'PUT',
  }),
  destroy: (apiFetch, url, opts) => apiFetch(url, null, {
    ...opts,
    method: 'DELETE',
  }),
}
const addMethods = apiFetch => {
  const addons = {}
  Object.entries(methods).forEach(([method, handler]) => {
    addons[method] = handler.bind(null, apiFetch)
  })
  return Object.assign(apiFetch, addons)
}

const NO_BODY_METHODS = ['HEAD', 'OPTIONS']

const getApiWrapper = memoizeOne(store => {
  // TODO: Make apiFetch return body even on non-200 responses. See !14.
  let fourOhOnes = 0
  const fourOhThrees = {}
  let abortController = new (typeof AbortController === 'undefined' ? function AbortController() {} : AbortController)()
  let currentMembership
  const apiFetch = async (path = '', body = null, opts = {}) => {
    const { auth, currentFacility, isOnline, myCurrentMembershipId: storeCurrent } = store.select([
      'selectAuth',
      'selectCurrentFacility',
      'selectIsOnline',
      'selectMyCurrentMembershipId'
    ])
    if (!isOnline) throw ioErrorFactory('Internet unavailable')
    if (storeCurrent && currentMembership && storeCurrent !== currentMembership) {
      logger.warn('membership changed, aborting all in-flight requests')
      abortController.abort()
      abortController = new (typeof AbortController === 'undefined'
        ? function AbortController() { return { abort() { console.warn('AbortController not available') } } }
        : AbortController
      )()
    }
    if (storeCurrent) {
      currentMembership = storeCurrent
    }
    const {
      authenticated = true,
      headers: optsHeaders,
      method = 'GET',
      allowedCodes = [],
      download = false,
      ...options
    } = opts
    const { access, refresh } = getTokens()

    const facilityId = currentFacility?.id
    const headers = new Headers({
      ...getDefaultHeaders(access, facilityId),
      ...optsHeaders,
    })

    let url = path.startsWith('http') ? path : `${config.API_URL}${path}`
    if ((method === 'GET' || method === 'DELETE') && body) {
      url += `?${queryString.stringify(body)}`
    }
    if (authenticated && (!auth?.authenticated || !access)) {
      throw ioErrorFactory(`Not authenticated. Cannot access "${url}"`)
    }
    const fetchOptions = { signal: abortController.signal, ...options, method, headers }
    if (body && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      fetchOptions.body = JSON.stringify(Array.isArray(body) ? [...body] : { ...body })
    }

    try {
      const response = await fetch(url, fetchOptions)

      if (!response.ok && !response.redirected) {
        if (response.status === 401) {
          fourOhOnes += 1
          if (fourOhOnes > 10) {
            captureEvent({
              message: 'Logging out due to repeated authorization failures',
              extra: { auth: store.selectAuth(), tokens: getTokens() },
              level: 'warning',
            })
            await store.doLogout()
            return false
          }
          if (refresh) {
            await refreshTokens(store)
            return apiFetch(path, body, opts)
          }
          if (store.selectAuth().authenticated) {
            const extra = { auth: store.selectAuth(), tokens: getTokens() }
            logger.warn('[401] refresh token missing:', extra)
            captureEvent({
              message: 'Logging out due to missing refresh token.',
              extra,
              level: 'warning',
            })
            store.doLogout()
            return false
          }
          if (typeof response.json === 'function') {
            let json
            try {
              json = await response.json()
            } catch (error) {
              console.error('401 response not valid JSON:', error)
            }
            throw ioErrorFactory('Error: API request failed', response.status, json)
          }
          return false
        }
        if (response.status === 403) {
          fourOhThrees[path] = (fourOhThrees[path] ?? 0) + 1
          const message = `Failed permissions check for ${config.API_URL}${path}`
          addBreadcrumb({
            category: 'permissions',
            message,
            data: response,
            level: 'warning',
          })
          if (fourOhThrees[path] > 3) {
            const error = ioErrorFactory(
              message,
              response.status,
              response
            )
            error.permanent = true
            throw error
          }
        }
        if (response.status === 412) {
          throw ioErrorFactory(
            'Client data out of date',
            response.status,
            response,
            { url, body, method, headers }
          )
        }
      }
      if (response.ok) {
        if (fourOhThrees[path]) fourOhThrees[path] = 0
        if (fourOhOnes) fourOhOnes = 0
      }

      if (store.selectMaintenanceModeEnabled() && response.ok) {
        store.doMaintenanceModeDisable()
      }

      if (response.headers.has('X-Maintenance')) {
        if (!store.selectMaintenanceModeEnabled()) {
          store.doMaintenanceModeEnable()
        }
      }

      if (download) {
        if (!response.ok) {
          return false
        }

        try {
          const blob = await response.blob()
          const file = global.URL.createObjectURL(blob)
          global.location.assign(file)
          return true
        } catch (error) {
          logger.error('unable to download file:', error.toString())
          return false
        }
      }

      try {
        let json
        if (
          response.status === 204
            || !response.body
            || typeof response.json !== 'function'
            || NO_BODY_METHODS.includes(method)
        ) {
          json = null
        } else {
          json = await response.json()
        }
        if (!response.ok && !allowedCodes.includes(response.status)) {
          throw ioErrorFactory('Error: API request failed', response.status, json)
        }
        return json
      } catch (error) {
        if (
          error instanceof SyntaxError
          && error.toString().match(/JSON\.parse/)
        ) {
          const rawResponse = await response.text()
          logger.error('invalid response received:', error.toString())
          logger.error('raw response:', rawResponse)
        }
        throw error
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.warn('request was aborted:', { url, fetchOptions })
        return REQUEST_ABORTED
      }
      throw error
    }
  }

  if (debugMode && notProd) {
    global.apiFetch = apiFetch
  }

  return addMethods(apiFetch)
})

export const useApiFetch = () => {
  const store = useReduxBundlerStore()

  return getApiWrapper(store)
}

const queryKeyOmitter = omit(['queryKey'])
/**
 *
 * @typedef {function} QueryFn
 * @param {Object} kwargs - the object of default params
 * @param {function} kwargs.apiFetch - apiFetch function
 * @param {import('redux-bundler').Store} kwargs.store - redux-bundler store
 * @param {Object} kwargs.params - object of query params
 * @param {string} kwargs.path - API URL pathname or queryKey prefix
 */

/**
 * Wraps useQuery to use the apiFetch function for fetching data.
 * @param {Object} config - configuration object
 * @param {Object} config.apiOptions - options to pass to apiFetch
 * @param {Object} config.params - query params to pass to apiFetch
 * @param {String} config.path - URL pathname to pass to apiFetch
 * @param {QueryFn} config.queryFn - function to use for fetching data, called with apiFetch and store
 * @param {import('@tanstack/react-query').UseQueryOptions} config.queryOptions - options to pass to useQuery
 * @returns {import('@tanstack/react-query').UseQueryResult} - query result
 */
export const useApiQuery = ({
  apiOptions = EMPTY_OBJECT,
  params = null,
  path = '',
  queryFn = null,
  queryKeyOverride,
  queryOptions = EMPTY_OBJECT,
}) => {
  const store = useReduxBundlerStore()
  const apiFetch = getApiWrapper(store)
  const queryKey = useMemo(() => queryKeyOverride ?? [...path.split('/'), params].filter(Boolean), [path, params, queryKeyOverride])
  if (queryOptions.queryKey) {
    throw new TypeError('To override useApiQuery-generated queryKey, pass top-level queryKeyOverride')
  }
  if (apiOptions.method && apiOptions.method !== 'GET') {
    throw new TypeError('useApiQuery only supports GET requests')
  }

  return useQuery({
    queryClient,
    queryKey,
    ...queryKeyOmitter(queryOptions),
    queryFn: queryFn
      ? queryFnKwargs => queryFn({ ...queryFnKwargs, apiFetch, params, path, store })
      : ({ signal }) => apiFetch(path, params, { signal, ...apiOptions }),
  })
}

export default getApiWrapper
